import { MONTHS, MONTHS_SHORT } from "../data";

export const roundDP = (value, len) => {
  value = Number(value).toFixed(len);

  const [bfDec, afDec] = value.toString().split(".");

  let s = "";

  if (afDec) {
    s = afDec.slice(0, len);
  }

  for (let i = s.length; i < len; i++) {
    s += "0";
  }

  return `${Number(bfDec).toLocaleString()}.${s}`;
};

export const roundDP2 = (value, len) => {
  value = Number(value).toFixed(len);

  const [bfDec, afDec] = value.toString().split(".");

  if (bfDec.length > 9) {
    const slice = bfDec.length - 9;
    const billion = bfDec.slice(0, slice);
    const sub = bfDec[slice];

    return `${billion}${+sub >= 1 ? `.${sub}` : ""}b`;
  }

  if (bfDec.length > 6) {
    const slice = bfDec.length - 6;
    const million = bfDec.slice(0, slice);
    const sub = bfDec[slice];

    return `${million}${+sub >= 1 ? `.${sub}` : ""}m`;
  }

  // if (bfDec.length > 3) {
  //   const slice = bfDec.length - 3;
  //   const thousand = bfDec.slice(0, slice);
  //   const sub = bfDec[slice];

  //   return `${thousand}${+sub >= 1 ? `.${sub}` : ""}k`;
  // }

  let s = "";

  if (afDec) {
    s = afDec.slice(0, len);
  }

  for (let i = s.length; i < len; i++) {
    s += "0";
  }

  return `${Number(bfDec).toLocaleString()}.${s}`;
};

export const copyData = (text, stateUpdate) => {
  navigator.clipboard.writeText(text).then(
    () => {
      stateUpdate(true);
      const interval = window.setInterval(() => {
        stateUpdate(false);
        window.clearInterval(interval);
      }, 1500);
    },
    () => {
      // Failed to copy do nothing
    }
  );
};

export const getRandomBG = () => {
  const colors = ["#4687d5", "#D5466C", "#46D58E", "#D5CA46", "#B846D5"];

  return colors[Math.floor(Math.random() * colors.length)];
};

export const isNumber = (value) => {
  const re = /^[0-9\b]+$/;

  // if value is not blank, then test the regex
  return value === "" || re.test(value);
};

export const get_date_time = (date_string, short) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth();
  const year = new_date.getFullYear();

  const hours = new_date.getHours();
  const meridian = hours < 12 ? "AM" : "PM";

  let hours12format = hours % 12;
  hours12format = hours12format ? hours12format : 12;

  const comp_date = `${day} ${
    short ? MONTHS_SHORT[month] : MONTHS[month]
  } ${year}`;
  const comp_time = `${String(hours12format).padStart(2, "0")}:${String(
    new_date.getMinutes()
  ).padStart(2, "0")}:${String(new_date.getSeconds()).padStart(
    2,
    "0"
  )} ${meridian}`;

  return [comp_date, comp_time];
};

export const get_date = (date_string) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth();
  const year = new_date.getFullYear();

  const hours = new_date.getHours();
  const meridian = hours < 12 ? "AM" : "PM";

  let hours12format = hours % 12;
  hours12format = hours12format ? hours12format : 12;

  const comp_date = `${day} ${MONTHS[month]} ${year}`;
  const comp_time = `${String(hours12format).padStart(2, "0")}:${String(
    new_date.getMinutes()
  ).padStart(2, "0")}:${String(new_date.getSeconds()).padStart(
    2,
    "0"
  )} ${meridian}`;

  return `${comp_date} ${comp_time}`;
};

export const sleep = (milliseconds) => {
  return new Promise((resolve, reject) => {
    const interval = window.setInterval(() => {
      resolve("Woken Up");
      window.clearInterval(interval);
    }, milliseconds);
  });
};
