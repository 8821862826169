import axios from "axios";

const instance = axios.create({
  //baseURL: "https://team.mybtcnigeria.xyz/api/sekipay",
  baseURL: "https://hiftigh.sekiapp.com/api/sekipay",

  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.code === "ERR_BAD_REQUEST" && error.response.status === 401) {
      localStorage.removeItem("app_state");
      window.location.assign("/sign-in");
    }
    return Promise.reject(error);
  }
);

export default instance;
