import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import Preloader from "./components/Preloader/Preloader";

import SignOut from "./pages/SignOut/SignOut";

const Login = lazy(() => import("./pages/Login/Login"));

const Home = lazy(() => import("./pages/Home/Home"));

const Pay = lazy(() => import("./pages/Pay/Pay"));
const PayDetails = lazy(() => import("./pages/Pay/PayDetails"));
const PaySuccessful = lazy(() => import("./pages/Pay/PaySuccessful"));

const Deposit = lazy(() => import("./pages/Deposit/Deposit"));

const PaymentDetails = lazy(() =>
  import("./pages/PaymentDetails/PaymentDetails")
);
const DepositDetails = lazy(() =>
  import("./pages/DepositDetails/DepositDetails")
);

const NotFound = lazy(() => import("./pages/NotFound/NotFound"));

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Preloader />}>
              <Home />
            </Suspense>
          }
        />

        <Route
          path="/pay"
          element={
            <Suspense fallback={<Preloader />}>
              <Pay />
            </Suspense>
          }
        />
        <Route
          path="/pay/details"
          element={
            <Suspense fallback={<Preloader />}>
              <PayDetails />
            </Suspense>
          }
        />
        <Route
          path="/pay/success"
          element={
            <Suspense fallback={<Preloader />}>
              <PaySuccessful />
            </Suspense>
          }
        />

        <Route
          path="/deposit"
          element={
            <Suspense fallback={<Preloader />}>
              <Deposit />
            </Suspense>
          }
        />

        <Route
          path="/payment-details/:hash"
          element={
            <Suspense fallback={<Preloader />}>
              <PaymentDetails />
            </Suspense>
          }
        />
        <Route
          path="/deposit-details/:hash"
          element={
            <Suspense fallback={<Preloader />}>
              <DepositDetails />
            </Suspense>
          }
        />

        <Route path="/sign-out" element={<SignOut />} />
        <Route
          path="/login"
          element={
            <Suspense fallback={<Preloader />}>
              <Login />
            </Suspense>
          }
        />

        <Route
          path="*"
          element={
            <Suspense fallback={<Preloader />}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
