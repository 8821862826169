import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import sekipay_client from "../../api/sekipay_client";

import { userActions } from "../../store";

import { sleep } from "../../utils/func";

const withAuth = (Component) => {
  return (props) => {
    const dispatch = useDispatch();

    const { loggedIn, accessToken } = useSelector((state) => state.user);

    const [reloadProfile, setReloadProfile] = useState(true);

    // Reload Profile
    useEffect(() => {
      if (!accessToken) return;

      sekipay_client({
        url: `/get-profile`,
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("Error fetching profile");

          const user = res.data.message;

          dispatch(userActions.updateUserDetails(user));
        })
        .catch((err) => {
          // do nothing
        })
        .finally(() => {
          sleep(15000).then(() => {
            setReloadProfile((rp) => !rp);
          });
        });
    }, [reloadProfile, accessToken, dispatch]);

    return loggedIn ? <Component {...props} /> : <Navigate to="/login" />;
  };
};

export default withAuth;
