import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import Preloader from "../../components/Preloader/Preloader";

const SignOut = () => {
  const navigate = useNavigate();

  const accessToken = useSelector((state) => state.user.accessToken);

  useEffect(() => {
    api_client({
      method: "POST",
      url: "/logout",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).finally(() => {
      localStorage.removeItem("app_state");
      window.location.reload();
    });
  }, [accessToken, navigate]);

  return <Preloader />;
};

export default withAuth(SignOut);
