import { configureStore, createSlice, current } from "@reduxjs/toolkit";

const states = ["user", "withdraw", "cache"];

const initialState = {
  user: {
    loggedIn: false,
    userDetails: null,
    accessToken: null,
  },
  withdraw: {
    bank: null,
    account_number: null,
    account_name: null,
    amount: null,
    fee: null,
    message: null,
    transaction: null,
    OTP: null,
    OTPMessage: null,
    LongOTPMessage: null,
  },
  cache: {
    withdrawalBeneficiaries: [],
  },
};

let appState = (() => {
  let state = {};

  const saved_state = JSON.parse(localStorage.getItem("app_state") ?? "{}");

  states.forEach((stateKey) => {
    state[stateKey] = saved_state[stateKey] ?? initialState[stateKey];
  });

  return state;
})();

console.log(appState);

const saveAppState = () => {
  const updated_state = {};

  states.forEach((stateKey) => {
    updated_state[stateKey] = appState[stateKey] ?? initialState[stateKey];
  });

  localStorage.setItem("app_state", JSON.stringify(updated_state));
};

const updateAppState = (key, updatedState) => {
  appState[key] = updatedState;

  saveAppState();
};

// const resetAppState = () => {
//   appState = initialState;

//   saveAppState();
// };

const userSlice = createSlice({
  name: "user",
  initialState: appState.user,
  reducers: {
    login(state, data) {
      const payload = data.payload;

      state.loggedIn = true;
      state.accessToken = payload.token;
      state.userDetails = payload.user;

      updateAppState("user", current(state));
    },
    updateUserDetails(state, { payload }) {
      state.userDetails = payload;

      updateAppState("user", current(state));
    },
  },
});

export const userActions = userSlice.actions;

const withdrawSlice = createSlice({
  name: "withdraw",
  initialState: appState.withdraw,
  reducers: {
    addWithdrawDetails(state, { payload }) {
      state.bank = payload.bank;
      state.account_number = payload.account_number;
      state.account_name = payload.account_name;
      state.amount = payload.amount;
      state.fee = payload.fee;
      state.message = payload.message;
      state.transaction = payload.transaction;

      updateAppState("withdraw", current(state));
    },
    updateOTP(state, { payload: { OTPMessage, LongOTPMessage } }) {
      state.OTP = true;
      state.OTPMessage = OTPMessage;
      state.LongOTPMessage = LongOTPMessage;

      updateAppState("withdraw", current(state));
    },
    clearWithdrawalDetails(state) {
      state.bank = null;
      state.account_number = null;
      state.account_name = null;
      state.amount = null;
      state.fee = null;
      state.message = null;
      state.transaction = null;
      state.OTP = null;
      state.OTPMessage = null;
      state.LongOTPMessage = null;

      updateAppState("withdraw", current(state));
    },
  },
});

export const withdrawActions = withdrawSlice.actions;

const cacheSlice = createSlice({
  name: "cache",
  initialState: appState.cache,
  reducers: {
    updateWithdrawalBeneficiaries(state, { payload }) {
      state.withdrawalBeneficiaries = payload;

      updateAppState("cache", current(state));
    },
  },
});

export const cacheActions = cacheSlice.actions;

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    withdraw: withdrawSlice.reducer,
    cache: cacheSlice.reducer,
  },
});
