import sekiLogo from "../../assets/images/seki-logo.png";

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="logo-container">
        <img src={sekiLogo} alt="" />
      </div>
    </div>
  );
};

export default Preloader;
